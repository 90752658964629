


.App {
  position: relative;
  text-align: center;
}

.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url('assets/imgs/background.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: brightness(40%);
  z-index: -1;
  pointer-events: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Steadman';
  src: url('fonts/STEADMAN.TTF') format('truetype');
}
@font-face {
  font-family: 'Steadman2';
  src: url('fonts/DkRavenheartRegular-zL2w.otf') format('truetype');
}

h1 {
  font-family: 'Steadman';
  font-size: 80px;
  margin: 0;
  color: antiquewhite;
  letter-spacing: 12px;
  margin-bottom: -30px;
  /*Add this line for letter spacing*/
}

h2 {
  font-family: 'Steadman2';
  font-size: 60px;
  margin: 0;
  /* color: antiquewhite; */
  letter-spacing: 3px;
  /*Add this line for letter spacing*/
}

p {
    font-family: 'Courier New', monospace;
      font-size: 16px;
}

#closeModalButton {
  font-family: 'Steadman';
  font-size: 70px;
  padding-right: 20px;
  margin-top: -20px;
}

body {
  position: relative;
  min-height: 100vh;
  background-color: black;
}

.bmc-button img {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
}
.bmc-btn-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

#trumpPortrait {
  position: fixed;
  top: 50px;
  right: 20px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 6px solid #DE0100;
}

#bidenPortrait {
  position: fixed;
  top: 50px;
  left: 20px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 6px solid #0015BC;
}

#mainShareButtons {
    position: fixed;
      bottom: 20px;
      left: 20px;

}

#modalShareButtons {
  width: 100%;
}

#articleText {
  text-align: left;
  font-size: 10px;
}